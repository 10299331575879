<template>
    <div>
        <van-cell-group title="飞鱼数据">
            <van-cell
                v-for="item in ReportList"
                :key="item.reportId"
                :title="item.reportName"
                is-link
                :to="'/hp/statistic?reportId=' + item.reportId"
            />
        </van-cell-group>
    </div>
</template>

<script>
import { queryReportList } from "@/api/feiyu/index"
export default {
    data() {
        return {
            ReportList: []
        }
    },
    async created() {
        const { value } = await queryReportList()
        this.ReportList = value
    },
    methods: {}
}
</script>

<style scoped lang="less"></style>
